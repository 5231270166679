import React, { useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import PartnerForm from "../../components/Forms/PartnerForm";
import PartnerModal from "../../components/PartnerModal";
import Polygon from "./polygon.svg";
import "./styles.scss";

function PartnerPage() {
	const [activeModal, setActiveModal] = useState(null);
	const formSection = useRef(null);

	const data = useStaticQuery(graphql`
		query {
			contentfulPartnerPage {
				title
				description
				partnerSection {
					name
					partners {
						id
						name
						logo {
							file {
								contentType
								url
								fileName
								details {
									image {
										width
										height
									}
								}
							}
						}
						url
						description {
							childMarkdownRemark {
								html
							}
						}
					}
				}
			}
		}
	`);

	const {
		contentfulPartnerPage: { title, description, partnerSection },
	} = data;

	const handleScrollToForm = () => {
		formSection.current.focus();
	};

	const handleCloseModal = () => {
		setActiveModal(null);
	};

	return (
		<Layout>
			<SearchEngineOptimization title={title} description={description} />
			<div className="partners">
				<section className="section section--is-spacious">
					<div className="container container--is-narrow">
						<h1 className="partners__heading">
							{title}
							<div className="partners__polygon">
								<img src={Polygon} alt="" aria-hidden="true" />
							</div>
						</h1>
						<p>{description}</p>
						<div className="partners__cta">
							<button
								className="btn btn--black-green btn--large"
								onClick={handleScrollToForm}
							>
								Become a partner
							</button>
						</div>
					</div>
				</section>
				<section className="section section--is-gray">
					<div className="container">
						{partnerSection.map((partnerType, index) => {
							const { partners } = partnerType;
							return (
								<div className="partners__type" key={index}>
									<h2 className="partners__type-heading">{partnerType.name}</h2>

									<ul className="partners__partner-list">
										{partners.map((partner, index) => {
											const { id, name, url, logo, description } = partner;

											return (
												<li key={id}>
													{description ? (
														<>
															<button
																onClick={() => setActiveModal(id)}
																className="partners__partner"
															>
																{logo?.file?.fileName.includes(".svg") ? (
																	<>
																		{logo?.file?.url && (
																			<div
																				style={{
																					backgroundImage: `url(${logo.file.url})`,
																					width: "200px",
																					height: "50px",
																					backgroundRepeat: "no-repeat",
																					backgroundPosition: "center",
																					backgroundSize: "contain",
																				}}
																			>
																				<span className="visually-hidden">{logo?.file?.fileName}</span>
																			</div>
																		)}
																	</>
																) : (
																	<>
																		{logo?.file?.url && (
																			<img src={logo.file.url} alt={name} />
																		)}
																	</>
																)}
															</button>
															<PartnerModal
																logo={logo}
																name={name}
																url={url}
																description={description}
																partnerType={partnerType.name}
																showModal={activeModal === id}
																handleCloseModal={handleCloseModal}
															/>
														</>
													) : (
														<a
															target="_blank"
															rel="noreferrer"
															href={url}
															className="partners__partner"
														>
															{logo?.file?.url && (
																<img src={logo.file.url} alt={name} />
															)}
														</a>
													)}
												</li>
											);
										})}
									</ul>
								</div>
							);
						})}
					</div>
				</section>
				<section className="section">
					<div className="container container--is-narrow">
						<div ref={formSection} tabIndex="0">
							<PartnerForm />
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
}

export default PartnerPage;
