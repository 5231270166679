import React from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";
import "./styles.scss";

const PartnerModal = ({
	logo,
	name,
	url,
	description,
	partnerType,
	handleCloseModal,
	showModal,
}) => {
	return (
		<ReactModal
			isOpen={showModal}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}
			onRequestClose={handleCloseModal}
			contentLabel="Partner Modal"
			className="partner-modal-container"
			overlayClassName="partner-modal-overlay"
			ariaHideApp={false}
		>
			<div className="partner-modal">
				<button
					className="partner-modal__close-button"
					onClick={handleCloseModal}
				>
					<MdClose aria-hidden="true" size={40} />
					<span className="visually-hidden">Close Modal</span>
				</button>
				<div className="partner-modal__content">
					<div className="partner-modal__partner-type">{partnerType}</div>
					<a target="_blank" rel="noreferrer" href={url} className="partner-modal__image">
						{logo?.file?.url ? (
							<img src={logo.file.url} alt={name} />
						) : (
							<span>{name}</span>
						)}
					</a>
					<div className="partner-modal__description">
						<div
							className="markdown"
							dangerouslySetInnerHTML={{
								__html: description.childMarkdownRemark.html,
							}}
						/>
					</div>
				</div>
			</div>
		</ReactModal>
	);
};

export default PartnerModal;

PartnerModal.propTypes = {
	logo: PropTypes.shape({
		file: PropTypes.shape({
			url: PropTypes.string,
		}),
	}),
	name: PropTypes.string,
	url: PropTypes.string,
	description: PropTypes.object,
	partnerType: PropTypes.string,
	handleCloseModal: PropTypes.func,
	showModal: PropTypes.bool,
};
